/* eslint-disable */
/* eslint-disable no-console */
import React from 'react';
import { createContext, useState } from 'react';
export const AgreementContext = createContext<any>('');
export function AgreementContextProvider(props: any) {
  const [TestInput, setTestInput] = useState();
  const [agrOptions, setAgrOptions] = useState();
  const [updateRecieptKey, setUpdateRecieptKey] = useState(null)
  const [searchCriteria, setsearchCriteria] = useState({
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
  });
  const [inventoryMapping, setinventoryMapping] = useState();
  const [resumebtnFeatureFlag, setresumebtnFeatureFlag] = useState();
  const [searchResult, setsearchResult] = useState();
  const [addedItem, setaddedItem] = useState();
  const [CustomerInfo, setCustomerInfo] = useState();
  const [itemArrayData, setitemArrayData] = useState();
  const [copyArrayData, setcopyArrayData] = useState();
  const [isSalesLeadFailed, setisSalesLeadFailed] = useState();
  const [viewAgreementData, setviewAgreementData] = useState();
  // const [packageList, setpackageListData] = useState();
  const [isReturnEnabledbyconfig, setisReturnEnabledbyconfig] = useState('0');
  const [viewAgrData, setviewAgrData] = useState();
  const [isCompCheck, setisCompCheck] = useState(false);
  const [proofFileName, setproofFileName] = useState();
  const [competitorData, setcompetitorData] = useState();
  const [depPercentage, setdepPercentage] = useState('0.00');
  const [PendingAgreementInputs, setPendingAgreementInputs] = useState();
  const [agreementCreateStatus, setagreementCreateStatus] = useState({
    agreementCreatedStatus: false,
  });
  const [agreementVoidStatus, setagreementVoidStatus] = useState({
    agreementVoidedStatus: false,
  });
  const [deliveryMethodData, setdeliveryMethodData] = useState();
  const [initialAgrData, setinitialAgrData] = useState();
  const [initialAgrTerm, setInitialAgrTerm] = useState();
  const [approvalAmountHolder, setapprovalAmountHolder] = useState();
  const [contextAgrId, setcontextAgrId] = useState('');
  const [getCustomerCoverage, setgetCustomerCoverage] = useState();
  const [agreementOptions, setagreementOptions] = useState();
  const [containDepartment, setcontainDepartment] = useState();
  const [containSubDepartment, setcontainSubDepartment] = useState();
  const [containCondition, setcontainCondition] = useState();
  const [containPackage, setcontainPackage] = useState();
  const [containCompetitor, setcontainCompetitor] = useState();
  const [selectedCompetitor, setselectedCompetitor] = useState();
  const [instantPendingData, setinstantPendingData] = useState();
  const [instantDropdownData, setinstantDropdownData] = useState();
  const [storeExpiryDate, setstoreExpiryDate] = useState('');
  const [ReturnReasons, setReturnReasons] = useState();
  const [amountDueResponse, setamountDueResponse] = useState();
  const [blockDE, setblockDE] = useState(false);
  const [offSetValue, setOffSetValue] = useState(1);
  const [entireGetCustomerData, setentireGetCustomerData] = useState<any>();
  const [GlobalcusId, setGlobalcusId] = useState('');
  const [coworkerCheck, setcoworkerCheck] = useState('');
  const [customerIdCheck, setcustomerIdCheck] = useState('');
  const [isAllDone, setIsAllDone] = useState(false);
  const [showProgress, setshowProgress] = useState(false);
  const [isSOEnabledbyconfig, setisSOEnabledbyconfig] = useState('0');
  const [isChoosePriceConfig, setisChoosePriceConfig] = useState('0');
  const [gotSOConfigResponse, setgotSOConfigResponse] = useState(false);
  const [SalesLeadPromo, setSalesLeadPromo] = useState<any>('');
  const [successDropdownValues, setsuccessDropdownValues]: any = useState({
    Schedule: '',
    Term: '',
    Deliverycharge: '',
    Duedate: '',
    DaysExtension: '',
    PromoCode: '',
    OptionalService: '',
    Range: '',
    Policy: '',
  });
  const [isInReinstateMode, setIsInReinstateMode] = useState('0');
  const [isSacDaysCompleted, setIsSacDaysCompleted] = useState('0');
  const [isReinstated, setisReinstated] = useState('0');
  const [epoAmount, setepoAmount] = useState('0.00');
  const [SalesInfoResponse, setSalesInfoResponse] = useState();
  const [storeSelectedPackageValue, setstoreSelectedPackage] = useState('');
  const [reasonDropDownValue, setreasonDropDownValue] = useState();
  const [competitorProofData, setcompetitorProofData] = useState();
  const [brokenPackageData, setbrokenPackageData] = useState([]);
  const [storeSelectedPackagePriceTag, setStoreSelectedPackagePriceTag] =
    useState(null);
  const [isReturn, setIsReturn] = useState<boolean>(false);
  const [ReturnAgrInformation, setReturnAgrInformation] = useState();
  const [DeliveryDate, setDeliveryDate] = useState('');
  const [DeliveryTime, setDeliveryTime] = useState('');
  const [returnReschedule, SetReturnReschedule] = useState(false);
  const [isDeliveryComplete, SetisDeliveryComplete] = useState(false);
  const [agreementInfoData, setAgreementInfoData] = useState({});
  const [addressId, SetaddressId] = useState();
  const [packageRules, setPackageRules] = useState();
  const [newAppointmentId, setNewAppointmentId] = useState();
  const [manualSignatureEnabled, setManualSignatureEnabled] = useState(false);
  const [AgrStoreNum, setAgrStoreNum] = useState('');
  const [rescheduleEnable, setRescheduleEnable] = useState(false);
  const [datePickerEnable, setdatePickerEnable] = useState(true);
  const [PromoAgrItemPrice, setPromoAgrItemPrice] = useState();
  const [createAAgreementTiemslot, setcreateAAgreementTiemslot] = useState(true);
  const [isExchangeEnabled, setIsExchangeEnabled] = useState<boolean>(false);
  const [exchangePeriod, setExchangePeriod] = useState('');
  const [returnPeriod, setReturnPeriod] = useState('');
  const [checkedState, setcheckedState] = useState(false);
  let [StoreExceptiontype, setStoreExceptiontype] = useState<any>({
    agrRateorTermEdited: false,
    competitorChecked: false,
    sacPriceEdited: false,
  })

  let [storeExceptionPayload, setstoreExceptionPayload] = useState<any>([]);

  //cappedFreeTime starts
  const [cappedFreeTimeValues, setCappedFreeTimeValues] = useState<any>({
    cappedFreeTimePercentage: 0,
    cappedFreeTimeRegularFlag: false,
    cappedFreeTimeServiceFlag: false,
    cappedFreeTimeRetentionFlag: false,
    cappedFreeTimeInitPaymentFlag: false,
    cappedFreeTimePromoFlag: false,
    cappedFreeTimePopup: false,
    enableCappedFreeTimeFlag: false
  });

  const [cappedFreeTimeResponseValues, setCappedFreeTimeResponseValues] = useState<any>({
    cappedFreeTimeSMRRAmount: 0,
    cappedFreeTimeRegular: 0,
    cappedFreeTimeService: 0,
    cappedFreeTimeRetention: 0,
    cappedFreeTimeInitPayment: 0,
    cappedFreeTimePromo: 0,
  });
  //capped End

  //Exchange Indication
  const [exchangeNavigationPopup, setExchangeNavigationPopup] = useState<boolean>(false);
  const [isExchangeAgreement, setExchangeAgreement] = useState<boolean>(false);
  const [exchangeAgreementId, setExchangeAgreementId] = useState<any>(0);
  const [openExchangeNavigationPopup, setOpenExchangeNavigationPopup] = useState<any>(false);
  const [exchangeStarted, setExchangeStarted] = useState<boolean>(false);
  const [exchangeTwoFactorAuth, setExchangeTwoFactorAuth] = useState<boolean>(false);
  const [isRacExchangeFuncEnabled, setRacExchangeFuncEnabled] = useState<boolean>(false);
  const [deliveryStatus, setDeliveryStatus] = useState<any>(false);
  const [cappedFreeTimeApproverId, setCappedFreeTimeApproverId] = useState<string>('');
  const [cappedFreeTimeAuthenticated, setCappedFreeTimeAuthenticated] = useState(true);
  const [getCustomerResponse, setGetCustomerResponse] = useState('')
  const [currentEmpRole, setCurrentEmpRole] = useState<string>('');
  const [decliedApproved, setDecliedApproved] = useState<boolean>(false);
  const [hardCustomerCompleted, setHardCustomerCompleted] = useState<boolean>(true);
  const [deDeclinedApprovalCompleted, setDeDeclinedApprovalCompleted] = useState<boolean>(false);
  return (
    <div>
      <AgreementContext.Provider
        value={{
          Itemsearch: [TestInput, setTestInput],
          AgreementOptions: [agrOptions, setAgrOptions],
          SearchCriteriaContext: [searchCriteria, setsearchCriteria],
          searchResultContext: [searchResult, setsearchResult],
          addedItemContext: [addedItem, setaddedItem],
          CustomerInfoContext: [CustomerInfo, setCustomerInfo],
          ItemArrayContext: [itemArrayData, setitemArrayData],
          CopyArrayContext: [copyArrayData, setcopyArrayData],
          viewAgreementContext: [viewAgreementData, setviewAgreementData],
          competitorDataContext: [competitorData, setcompetitorData],
          // packageDataContext: [packageList, setpackageListData],
          viewAgrDataContext: [viewAgrData, setviewAgrData],
          AgreementCreateStatusContext: [
            agreementCreateStatus,
            setagreementCreateStatus,
          ],
          AgreementVoidStatusContext: [
            agreementVoidStatus,
            setagreementVoidStatus,
          ],
          PendingAgreementDetails: [
            PendingAgreementInputs,
            setPendingAgreementInputs,
          ],
          inventoryMapping,
          setinventoryMapping,
          AgrStoreNum,
          setAgrStoreNum,
          TestInput,
          setTestInput,
          PromoAgrItemPrice,
          setPromoAgrItemPrice, searchCriteria,
          setsearchCriteria,
          setReturnReasons,
          ReturnReasons,
          setamountDueResponse,
          amountDueResponse,
          searchResult,
          setsearchResult,
          addedItem,
          setaddedItem,
          SetaddressId,
          addressId,
          updateRecieptKey,
          setUpdateRecieptKey,
          itemArrayData,
          setitemArrayData,
          copyArrayData,
          setcopyArrayData,
          competitorData,
          setcompetitorData,
          viewAgrData,
          isReturn,
          isReturnEnabledbyconfig,
          setisReturnEnabledbyconfig,
          setIsReturn,
          resumebtnFeatureFlag,
          setresumebtnFeatureFlag,
          DeliveryTime,
          setDeliveryTime,
          DeliveryDate,
          setDeliveryDate,
          returnReschedule,
          SetReturnReschedule,
          isDeliveryComplete,
          SetisDeliveryComplete,
          ReturnAgrInformation,
          setReturnAgrInformation,
          // packageList,
          // setpackageListData,
          agreementCreateStatus,
          setagreementCreateStatus,
          proofFileName,
          setproofFileName,
          isCompCheck,
          setisCompCheck,
          depPercentage,
          setdepPercentage,
          initialAgrData,
          setinitialAgrData,
          agreementVoidStatus,
          setviewAgreementData,
          viewAgreementData,
          contextAgrId,
          setcontextAgrId,
          getCustomerCoverage,
          setgetCustomerCoverage,
          agreementOptions,
          setagreementOptions,
          containDepartment,
          customerIdCheck,
          setcustomerIdCheck,
          setcontainDepartment,
          containSubDepartment,
          setcontainSubDepartment,
          containCondition,
          setcontainCondition,
          containPackage,
          setcontainPackage,
          containCompetitor,
          setcontainCompetitor,
          setselectedCompetitor,
          selectedCompetitor,
          instantPendingData,
          setinstantPendingData,
          instantDropdownData,
          setinstantDropdownData,
          deliveryMethodData,
          setdeliveryMethodData,
          setviewAgrData,
          setstoreExpiryDate,
          blockDE,
          setblockDE,
          setentireGetCustomerData,
          entireGetCustomerData,
          setsuccessDropdownValues,
          successDropdownValues,
          setapprovalAmountHolder,
          approvalAmountHolder,
          storeExpiryDate,
          isSalesLeadFailed,
          setisSalesLeadFailed,
          offSetValue,
          setOffSetValue,
          setIsInReinstateMode,
          isInReinstateMode,
          isSacDaysCompleted,
          setIsSacDaysCompleted,
          isReinstated,
          setisReinstated,
          setepoAmount,
          epoAmount,
          SalesInfoResponse,
          setSalesInfoResponse,
          isSOEnabledbyconfig,
          setisSOEnabledbyconfig,
          GlobalcusId,
          setGlobalcusId,
          coworkerCheck,
          setcoworkerCheck,
          gotSOConfigResponse,
          setgotSOConfigResponse,
          setstoreSelectedPackage,
          storeSelectedPackageValue,
          setreasonDropDownValue,
          reasonDropDownValue,
          setcompetitorProofData,
          competitorProofData,
          isAllDone,
          setIsAllDone,
          setshowProgress,
          showProgress,
          setStoreSelectedPackagePriceTag,
          storeSelectedPackagePriceTag,
          setbrokenPackageData,
          brokenPackageData,
          setisChoosePriceConfig,
          isChoosePriceConfig,
          agreementInfoData,
          setAgreementInfoData,
          packageRules,
          setPackageRules,
          newAppointmentId,
          setNewAppointmentId,
          // switchOutData,
          manualSignatureEnabled,
          setManualSignatureEnabled,
          rescheduleEnable,
          setRescheduleEnable,
          datePickerEnable,
          setdatePickerEnable,
          createAAgreementTiemslot,
          setcreateAAgreementTiemslot,
          isExchangeEnabled,
          setIsExchangeEnabled,
          exchangePeriod,
          setExchangePeriod,
          returnPeriod,
          setReturnPeriod,
          StoreExceptiontype,
          setStoreExceptiontype,
          storeExceptionPayload,
          setstoreExceptionPayload,
          checkedState,
          setcheckedState,
          cappedFreeTimeValues,
          setCappedFreeTimeValues,
          cappedFreeTimeResponseValues,
          setCappedFreeTimeResponseValues,
          initialAgrTerm, setInitialAgrTerm,
          exchangeNavigationPopup,
          setExchangeNavigationPopup,
          isExchangeAgreement,
          setExchangeAgreement,
          exchangeAgreementId,
          setExchangeAgreementId,
          openExchangeNavigationPopup,
          setOpenExchangeNavigationPopup,
          exchangeStarted,
          setExchangeStarted,
          exchangeTwoFactorAuth,
          setExchangeTwoFactorAuth,
          isRacExchangeFuncEnabled,
          setRacExchangeFuncEnabled,
          deliveryStatus,
          setDeliveryStatus,
          cappedFreeTimeApproverId,
          setCappedFreeTimeApproverId,
          cappedFreeTimeAuthenticated,
          setCappedFreeTimeAuthenticated,
          getCustomerResponse,
          setGetCustomerResponse,
          currentEmpRole, setCurrentEmpRole,
          decliedApproved, 
          setDecliedApproved,
          hardCustomerCompleted, 
          setHardCustomerCompleted,
          deDeclinedApprovalCompleted, 
          setDeDeclinedApprovalCompleted,
          SalesLeadPromo,
          setSalesLeadPromo
        }}
      >
        {props.children}
      </AgreementContext.Provider>
    </div>
  );
}